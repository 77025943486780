export const nodeEnv = process.env.NODE_ENV;

export const appEnv = import.meta.env.VITE_ENV;
export const commitSha = import.meta.env.VITE_COMMIT_SHA;
export const voimaVersion = import.meta.env.VITE_VOIMA_VERSION;

export const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
export const sentryEnv = import.meta.env.VITE_SENTRY_ENVIRONMENT;
export const sentryRelease = import.meta.env.VITE_SENTRY_RELEASE;

export const isTest = import.meta.env.VITE_ENV === "test";
export const isDevelopment = import.meta.env.VITE_ENV === "development";
export const isLocal = isTest || isDevelopment;
export const isStaging = import.meta.env.VITE_ENV === "staging";
export const isProduction = import.meta.env.VITE_ENV === "production";

export const apiUrl = import.meta.env.VITE_API_URL;

export const projektikarttaHost = import.meta.env.VITE_PROJEKTIKARTTA_HOST;
export const projektikarttaUrl = `https://${import.meta.env.VITE_PROJEKTIKARTTA_HOST}`;

export const o365ClientID = import.meta.env.VITE_O365_CLIENT_ID;
export const o365TenantID = import.meta.env.VITE_O365_TENANT_ID;
export const o365Tenant = import.meta.env.VITE_O365_TENANT;

export const sharepointSite = import.meta.env.VITE_SHAREPOINT_SITE;

export const frameAgreementsCollection = import.meta.env
  .VITE_FRAME_AGREEMENTS_COLLECTION;

export const publicUrl = import.meta.env.VITE_PUBLIC_URL;

// NOTE: localStorage.setItem("devLogEnabled", "true") to enable
const devLogEnabled = isLocal || localStorage.getItem("devLogEnabled");
export const devLogger = {
  // eslint-disable-next-line no-console
  log: (...args) => devLogEnabled && console.log(...args),
  // eslint-disable-next-line no-console
  debug: (...args) => devLogEnabled && console.debug(...args),
  // eslint-disable-next-line no-console
  info: (...args) => devLogEnabled && console.info(...args),
  // eslint-disable-next-line no-console
  warn: (...args) => devLogEnabled && console.warn(...args),
  // eslint-disable-next-line no-console
  error: (...args) => devLogEnabled && console.error(...args),
};
